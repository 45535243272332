var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.gotoHome } },
            [_vm._v(" 返回主页 ")]
          ),
        ],
        1
      ),
      _c(
        "a-steps",
        { attrs: { current: _vm.current } },
        _vm._l(_vm.steps, function (item) {
          return _c("a-step", { key: item.id, attrs: { title: item.title } })
        }),
        1
      ),
      _c("div", { staticClass: "steps-content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.index === 1,
                expression: "index === 1",
              },
            ],
          },
          [
            _c("ul", { staticClass: "typeList" }, [
              _c("li", { on: { click: _vm.next } }, [_vm._v(" 功能板块1 ")]),
              _c("li", { on: { click: _vm.next } }, [_vm._v(" 功能板块2 ")]),
              _c(
                "li",
                {
                  staticStyle: { "margin-right": "0" },
                  on: { click: _vm.next },
                },
                [_vm._v(" 功能板块3 ")]
              ),
              _c("li", { on: { click: _vm.next } }, [_vm._v(" 功能板块4 ")]),
              _c("li", { on: { click: _vm.next } }, [_vm._v(" 功能板块5 ")]),
              _c(
                "li",
                {
                  staticStyle: { "margin-right": "0" },
                  on: { click: _vm.next },
                },
                [_vm._v(" 功能板块6 ")]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.index === 2,
                expression: "index === 2",
              },
            ],
          },
          [
            _c("p", [_vm._v("功能板块：问题类别1")]),
            _c(
              "a-form-model",
              {
                attrs: {
                  model: _vm.form,
                  "label-col": _vm.labelCol,
                  "wrapper-col": _vm.wrapperCol,
                },
              },
              [
                _c(
                  "a-form-model-item",
                  { attrs: { label: "姓名" } },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "手机号" } },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.form.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "标题" } },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.form.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "title", $$v)
                        },
                        expression: "form.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "问题描述" } },
                  [
                    _c("a-input", {
                      attrs: { type: "textarea" },
                      model: {
                        value: _vm.form.desc,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "desc", $$v)
                        },
                        expression: "form.desc",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "添加附件" } },
                  [
                    _c(
                      "a-upload",
                      {
                        attrs: {
                          name: "file",
                          multiple: true,
                          headers: _vm.headers,
                        },
                        on: { change: _vm.handleChange },
                      },
                      [
                        _c(
                          "a-button",
                          [
                            _c("a-icon", { attrs: { type: "upload" } }),
                            _vm._v(" 选择文件 "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("span", [
                      _vm._v("支持.jpg、png；体积小于1M，最多上传3张图片"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "steps-action" },
        [
          _vm.current == _vm.steps.length - 1
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$message.success("后台处理中!")
                    },
                  },
                },
                [_vm._v(" 提交 ")]
              )
            : _vm._e(),
          _vm.current > 0
            ? _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "8px" },
                  on: { click: _vm.prev },
                },
                [_vm._v(" 上一步 ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }