<template>
    <div>
        <div class="content">
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </div>
        <a-steps :current="current">
            <a-step v-for="item in steps" :key="item.id" :title="item.title" />
        </a-steps>
        <div class="steps-content">
            <div v-show="index === 1">
                <ul class="typeList">
                    <li @click="next">
                        功能板块1
                    </li>
                    <li @click="next">
                        功能板块2
                    </li>
                    <li style="margin-right: 0" @click="next">
                        功能板块3
                    </li>
                    <li @click="next">
                        功能板块4
                    </li>
                    <li @click="next">
                        功能板块5
                    </li>
                    <li style="margin-right: 0" @click="next">
                        功能板块6
                    </li>
                </ul>
            </div>
            <div v-show="index === 2">
                <p>功能板块：问题类别1</p>
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="姓名">
                        <a-input v-model="form.name" />
                    </a-form-model-item>
                    <a-form-model-item label="手机号">
                        <a-input v-model="form.phone" />
                    </a-form-model-item>
                    <a-form-model-item label="标题">
                        <a-input v-model="form.title" />
                    </a-form-model-item>
                    <a-form-model-item label="问题描述">
                        <a-input v-model="form.desc" type="textarea" />
                    </a-form-model-item>
                    <a-form-model-item label="添加附件">
                        <a-upload
                            name="file"
                            :multiple="true"
                            :headers="headers"
                            @change="handleChange">
                            <a-button> <a-icon type="upload" /> 选择文件 </a-button>
                        </a-upload>
                        <span>支持.jpg、png；体积小于1M，最多上传3张图片</span>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
        <div class="steps-action">
            <a-button
                v-if="current == steps.length - 1"
                type="primary"
                @click="$message.success('后台处理中!')">
                提交
            </a-button>
            <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
                上一步
            </a-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Submit",
    data() {
        return {
            // 从0开始计数
            current: 0,
            // 当前显示哪一步操作
            index: 1,
            // 步骤条标题内容
            steps: [
                {
                    id: 1,
                    title: '选择功能分类'
                },
                {
                    id: 2,
                    title: '填写工单'
                }
            ],
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            // 表单提交数据
            form: {
                name: '',
                phone: '',
                title: '',
                desc: '',
            },
            // 上传
            headers: {
                authorization: 'authorization-text',
            }
        }
    },
    methods: {
        // 下一步
        next() {
            this.current++
            this.index = 2
        },
        // 上一步
        prev() {
            this.current--
            this.index = 1
        },
        gotoHome() {
            this.$router.push('/home')
        },
        // 上传文件
        handleChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList)
            }
            if (info.file.status === 'done') {
                this.$message.success(`${info.file.name} 文件上传成功`)
            } else if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} 文件上传失败`)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        display: flex;
        justify-content: flex-end;
        margin: -60px 0 30px 0;
        width: 100%;
    }
    .steps-content {
        margin: 30px 0 20px;
        .typeList {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 32%;
                text-align: center;
                padding: 30px 0;
                border: 1px solid #333;
                margin: 0 20px 20px 0;
                cursor: pointer;
            }
        }
    }
    .steps-action {
        text-align: center;
    }
</style>
